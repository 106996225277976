import type { RouteLocationRaw } from "#vue-router";
import { isClient } from "@vueuse/core";
import type { NuxtApp } from "#app";
import type { OpenOptions } from "#app/composables/router";

export async function useResolveRoute(
  to: RouteLocationRaw,
  nuxtApp?: NuxtApp,
  openOptions?: OpenOptions,
) {
  if (isClient) {
    return navigateTo(to, {
      external: true,
      open: openOptions,
    });
  }

  if (!nuxtApp) {
    console.error("useResolveRoute: nuxtApp is required in server");
    return;
  }

  return nuxtApp.runWithContext(async () => {
    await navigateTo(to, {
      external: true,
      open: openOptions,
    });
  });
}
